import React from 'react';

const Hero = () => {
    return(
        <div className="hero">
            <img src="frame.png"/>
            <input 
                className="searchBar"
                readonly
                value="scorektu"
            />
            <div className="hero-desc-div">
            
                <div className='hero-desc-subdiv'>
                    <h1>Attendex</h1>
                    <p>check the min no. of classes to attend for 75%</p>
                    <p>check the number of medical hours to be updated for 75%</p>
                </div>
                <div className='hero-desc-subdiv'>
                    <h1>Cgpa</h1>
                    <p>check your cgpa</p>
                </div>
                <div className='hero-desc-subdiv'>
                    <h1>Sgpa</h1>
                    <p>check your semester sgpa</p>
                </div>
                
            </div>
            
        </div>
    );
}
export default Hero;
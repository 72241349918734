import { useState } from 'react';
import './App.css';



function Banner() {




    return(
        <div class="marquee-w">
                <div class="marquee">
                    <span>This is an infinitely sliding text example&nbsp;&nbsp;&nbsp;</span>
                </div>
            </div>
    );
}

export default Banner;
const Footer = () => {
    return (
        <div className="footerDiv"> 
            <img  className="avatarImg" src="avatar.png" />
            <ul>
                <li><h5>Developed By</h5></li>
                <li><h5>NIKHIL MUKUNDAN</h5></li>
                <li><p>Computer Science and Engineering</p></li>
                <li><p>Vidya '21</p></li>
                <li><p>xnick126@gmail.com</p></li>
            </ul>
            <ul>
                <li><h5><a href="https://www.linkedin.com/in/juzznick">Linked In</a></h5></li>
                <li><h5><a href="https://www.instagram.com/juzznick/">Instagram</a></h5></li>
                <li><h5><a href="https://www.youtube.com/channel/UC0-Dkdfg8uoDzFHhYE22kIg">Youtube</a></h5></li>
            </ul>
            <div className="footerLogo">
                <h6>score<span>Ktu</span></h6>
            </div>

        </div>
    );
}

export default Footer;

import './App.css';
import Hero from './hero';
import Cgpa from './cgpa';
import Sgpa from './sgpa';
import Attendex from './attendex';
import Footer from './footer';
import Banner from './banner';
import { BrowseRouter, Routes, Route, Link, NavLink, BrowserRouter } from 'react-router-dom';



function App() {
  const setBlue = () => {
    document.getElementsByTagName("h1").classList.add("theme-blue");
    console.log("hi");
  } 
  return (
    
    
    
    
    
    
    <BrowserRouter>
      <nav>
          <ul className='navBar'>
            <li><NavLink to="/"><img className="navLogo"  src="homenew.png" /></NavLink></li>
            <li><NavLink to="attendex"><img className="navLogo"  src="a.png" /></NavLink></li>
            <li><NavLink to="cgpa"><img className="navLogo"  src="c.png" /></NavLink></li>
            <li><NavLink to="sgpa"><img className="navLogo"  src="s.png" /></NavLink></li>
          </ul>
      </nav>
      <main>
        <Routes>
          <Route index element={<Hero/>}/>
          <Route path="attendex" element={<Attendex/>}/>
          <Route path="sgpa" element={<Sgpa/>}/>
          <Route path="cgpa" element={<Cgpa/>}/>
        </Routes>
      </main>
      <Footer/> 
    </BrowserRouter>


    /*

    this isss the new oneeee under thisss

    <BrowserRouter>
      <Routes>
        <Route path='signup' element={<Signup/>}></Route>
        <Route path='login' element={<Login/>}></Route>
      </Routes>
    </BrowserRouter>
    
    */
    
  );
}

export default App;
